<template>
  <div @click="test">test</div>
</template>

<script  setup>
import { computed, ref } from 'vue'
import { TableV2FixedDir } from 'element-plus'
import { useStore } from 'vuex';

const store = useStore()

const test = () => {
  console.log('------store',store.getters.filters)
}
</script>
